

.collection-btn-more .btn:after, .custom__item-inner .rte-setting p a:after {
    content: "";
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    margin-left: 5px;
    margin-bottom: 2px;
    font-size: 10px;
}

.grid-view-items-inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
    padding: 0 60px;
}

.grid-view-item__title {
    font-size: 16px;
}

.product-price__price {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
}

.content-collection-img {
    margin-top: 40px;
}

.collection-btn-more {
    padding: 0 30px;
}

.collection-btn-more a {
    background: #fff;
    color: #000000;
    font-size: 20px;
}

.product-description-scroll {
    overflow: auto;
}

.product-single__thumbnails {
    display: flex;
    justify-content: center;
}

/****** PAGE TEXTILES ********/
.page-full-width {
    padding: 0 !important; 
    max-width: 100% !important;
}

.content-collection-img {
    margin-bottom: 60px;
}

.content-collection-img .background-media-text__image {
    background-size: cover;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
}

.collection-slider-container {
    grid-column: 4;
}

.collection-slider-container .section-header {
    margin-bottom: 0;
}

.collection-slider-container .section-header h4 {
    font-size: 36px;
    color: #fff;
    margin-bottom: 25px;
}

.collection-slider-container .section-button .btn {
    background: #fff;
    color: #000000;
    font-size: 20px;
    letter-spacing: 0px;
}
/***************************************/

.textile-slider-container {
    display: grid;
    align-items: center;
    justify-items: center;
  	background-size: cover;
}

.textile-slider-container h4 {
    margin: 0;
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
}

.content_page-textiles {
    padding: 40px 60px;
}

.content_page-textiles .rte h3 {
    margin-bottom: 0;
    margin-top: 6px;
}

.content_page-textiles .rte p {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}

.collection-slider-container .btn:after, .content_page-textiles a:after {
    content: "";
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    margin-left: 5px;
    margin-bottom: 2px;
    font-size: 10px;
}

.content_page-textiles a {
    border-bottom: none !important;
}

.custom-about-inner .background-media-text__image{
    background-size: cover;    
}


/******************* process ************************/

.custom-about {
    margin-top: 70px;
}

.custom-about h4, .process-content h4 {
    font-size: 36px;
    margin-bottom: 50px;
    text-align: center;
    text-transform: uppercase;
}

.custom-about-content {
    background: #fff;
    border: solid 1px #F3F3F3;
    bottom: 0;
    left: 30px;
    max-width: 600px;
    padding: 25px 20px;
    position: absolute;
}

.custom-about-content p {
    color: #000000;
    font-weight: 600;
}

.custom-about-inner {
    padding-bottom: 70px;
    position: relative;
}

.img-content img {
    height: 400px;
    object-fit: cover;
    width: 100%;
}

.process-content {
    background: #EFEFEF;
    margin-top: 140px;
    padding-top: 70px;
}

.process-content-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 40px 80px;
	gap: 50px;
}

.process-content-inner .title {
    align-items: center;
    color: #000000;
    display: grid;
    font-size: 30px !important;
	font-family: "Open Sans", sans-serif;
    font-weight: 900;
    gap: 15px;
    grid-template-columns: auto 1fr;
    text-transform: uppercase;
}

.process-content-inner .title span {
    align-items: center;
    background: #000000;
    color: #fff;
    display: grid;
    font-weight: 200;
    justify-items: center;
	height: 45px !important;
    width: 45px !important;
}

.process-content-inner:nth-child(odd) .process-info {
    grid-area: 1 / 2 / 2 / 4;
    padding-left: 70px;
}

.text-content p {
    text-align: justify;
    font-size: 20px !important;
    line-height: 28px !important;
}

.terms {
    margin-top: 25px;
    color: #000000;
    font-size: 18px;
}

#shopify-section-product-recommendations {
    padding: 70px;
    padding-bottom: 0;
}

#shopify-section-product-recommendations h2 {
    text-align: center;
    font-size: 35px;
    letter-spacing: 0px;
}

#shopify-section-product-recommendations ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 90px;
    gap: 50px;
}

#shopify-section-product-recommendations .product__img {
    width: 100%;
    object-fit: cover;
    height: 350px;
}

#shopify-section-product-recommendations .product__title, #shopify-section-product-recommendations .product__price {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
}

.product__title {
    margin-top: 20px;
}

/******************* END process ************************/


@media only screen and (max-width:992px){
	.content_page-textiles {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    }

    .content-collection-img .background-media-text__image {
        grid-template-columns: 1fr;
    }

    .collection-slider-container {
        grid-column: 1;
    }

    .collection-slider-container .section-header h4, .collection-slider-container .section-header .h4 {
        text-align: center;
    }

    .collection-slider-container .section-button {
        text-align: center;
    }

    .grid-view-items-inner {
        padding: 0 20px;
        grid-template-columns: 1fr;
        gap: 40px;
    }

    .grid-view-item {
        margin-bottom: 0;
    }
  
    .custom-about-inner {
      height: auto !important;
      padding-bottom: 0 !important;
    }

    .custom-about-content {
      top: 0;
      left: 0 !important;
      background: rgba(255, 255, 255, 0.6) !important;
      position: relative !important;
    }

    .custom-about h4, .process-content h4 {
      font-size: 30px !important;
      padding-top: 14px;
      margin-bottom: 26px !important;
    }

    .process-content {
      margin-top: 0 !important;
      padding-top: 42px !important;
    }

    .process-content-inner {
      padding: 30px 20px !important;
      grid-template-columns: 1fr !important;
      gap: 25px;
      padding-top: 0 !important;
    }

    .img-content img {
      height: 260px !important;
    }

    .process-content-inner:nth-child(odd) .process-info {
      grid-area: 1 !important;
      padding-left: 0 !important;
    }

    .process-content-inner .title {
      font-size: 26px !important;
    }

    .process-content-inner .title span {
      font-size: 24px;
      height: 34px !important;
      width: 34px !important;
    }

    .text-content p {
      font-size: 22px !important;
      line-height: 26px !important;
    }

    #shopify-section-product-recommendations {
      padding: 40px 20px !important;
    }

    #shopify-section-product-recommendations h2 {
      font-size: 26px !important;
    }

    #shopify-section-product-recommendations ul {
      margin-top: 40px !important;
      grid-template-columns: 1fr !important;
      gap: 30px !important;
    }

    #shopify-section-product-recommendations .product__img {
      height: 260px !important;
    }
}
